import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
  P,
} from "../../Components/Text";
import HeaderTitle from "../../Components/Title";
import GAHoc from "../../GA";
import Logo from "../../Components/Logo";
import bookData from "../../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProgressBar from "../../Components/ProgressBar";
import TitleSection from "../../Components/TitleSection";
import Book from "../../Components/Book";
import TurningPage from "../../Components/TurningPage";
import { changeHandler, validCheck } from "../../Components/MemberChecker";
import { expired } from "../../Components/Utils";

const StateResponder = ({
  state,
  goNext,
  goPrev,
  month_kr,
  name,
  inputRef,
  booklist,
  setProgress,
  setName,
  setDispState,
  autoComplete,
  setAutoComplete,
}) => {
  switch (state) {
    case 0:
      return (
        <VerticalBox>
          <TitleSection month_kr={month_kr} />
          <P>{"사용자의 닉네임을 입력하세요"}</P>
          <TextInput
            ref={inputRef}
            type="text"
            onChange={(e) =>
              changeHandler(e, setName, setDispState, 1, setAutoComplete)
            }
            placeholder={"닉네임"}
            onKeyUp={(e) => {
              if (e.key === "Backspace") {
                setAutoComplete([]);
              }
            }}
          />
          {autoComplete.length >= 1
            ? autoComplete.map((item) => {
                return (
                  <TextInput
                    key={item.name}
                    onClick={() => {
                      setName(item.name);
                      validCheck(item.expireAt, setDispState, 1);
                    }}
                    style={{
                      border: "none",
                      margin: "0px",
                      fontSize: "12px",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    readOnly
                    value={item.name}
                  />
                );
              })
            : null}
        </VerticalBox>
      );
    case 1:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} first>
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
부기의 2025년은 2달 간 하나의 키워드를 다루며 진행됩니다. 

키워드를 다루는 첫번째 달에는 다양한 책을 추천하며, 

여러분이 책 목록에서 고르거나 평소 읽고 싶었던 책 중 하나를 주제와 상관 없더라도 자유롭게 읽는 식으로 진행합니다.

여러분이 책을 읽으며 쉘하우스에 담아둔 모든 내용과 생각들을 모아 부기는 새로운 연결을 만들어 낼 것입니다.
  `}</Pre>
          </VerticalBox>
        </TurningPage>
      );
    case 2:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} setProgress={setProgress}>
          <Pre>{`
2025년의 첫번째 키워드는 '일'입니다. 

멤버 분들의 2025 프리뷰를 들여다보면 대부분의 내용이 여러분과 일에 대한 이야기였습니다. 

그도 그럴 것이 우리는 자는 시간을 제외한 일상의 대부분을 일을 하는데에 할애해 소진합니다. 

때문에 일은 삶을 위한 소모가 아닌, 그 자체로 의미있는 삶의 부분이어야 합니다.
            `}</Pre>
        </TurningPage>
      );
    case 3:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
유독 일에 대해 이야기하기를 좋아하는 사람들이 있습니다. 

그런 사람들에게 일은 삶의 일부입니다. 저는 그 사람들의 일 이야기를 읽을 때면 특별함을 느낍니다. 

그들은 자신의 일상을 한 순간도 소비의 관점으로 사용하지 않습니다. 

그들의 삶의 행적과 취향은 모두 일의 일부를 구성합니다.
  `}</Pre>
        </TurningPage>
      );
    case 4:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
진짜 자기의 '일'을 하는 사람의 일은 그들의 '삶'을 반영하고 있습니다. 

그러니 그토록 자신의 일을 사랑할 수 밖에 없죠. 

자신의 삶을 긍정하는만큼 일도 자연스레 긍정하게 될테니까요. 

저는 이번 달의 책으로 그런 사람들의 이야기를 3가지, 골랐습니다. 
  `}</Pre>
        </TurningPage>
      );
    case 5:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} last>
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <Divider />
            {Object.entries(booklist).map(([k, v]) => {
              return <Book key={k} objKey={k} bookInfo={v} />;
            })}
            <Divider />
          </VerticalBox>
        </TurningPage>
      );
    default:
      return (
        <HorizontalBox>
          <Info>안녕하세요 !</Info>
          <Logo src="/charactor1.png" alt="logo" />
        </HorizontalBox>
      );
  }
};
const BooklistJan = () => {
  const [name, setName] = useState("부기");
  const [progress, setProgress] = useState(0);
  const { month } = useParams();
  const { kr: month_kr, booklist } = bookData["2025"][month];
  const [dispState, setDispState] = useState(expired(month_kr[0]) ? 1 : 0);
  const [autoComplete, setAutoComplete] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);
  useEffect(() => {
    setProgress(dispState / 4);
  }, [dispState]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 0) {
      inputRef.current.focus();
    }
    if ((!expired(month_kr[0]), name === "부기" && dispState > 0)) {
      setDispState(0);
    }
  }, [name, dispState, month_kr]);

  const goNext = () => {
    setDispState(dispState + 1);
  };
  const goPrev = () => {
    setDispState(dispState - 1);
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder
          state={dispState}
          goNext={goNext}
          goPrev={goPrev}
          inputRef={inputRef}
          setDispState={setDispState}
          setName={setName}
          month_kr={month_kr}
          name={name}
          booklist={booklist}
          setProgress={setProgress}
          autoComplete={autoComplete}
          setAutoComplete={setAutoComplete}
        />
      </Container>
    </GAHoc>
  );
};
export default BooklistJan;
