import React, { useState } from "react";
import GAHoc from "../../GA";
import { Container, Divider } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import { P, SectionTitle, TextInput, VerticalBox } from "../../Components/Text";
import { LinkBtn } from "../../Components/Button";

const Meetup = () => {
  const [name, setName] = useState("");
  // const [book, setBook] = useState("");
  // const [role, setRole] = useState("말하는 이");

  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="부깃 아웃" />
        <VerticalBox>
          <SectionTitle>부깃 아웃</SectionTitle>
          <P>{"3월 부깃아웃은 역사를 다룹니다."}</P>
          <P>
            {
              "모든 참가자 분들께 세계사 책 [인생 처음으로 세계사가 재밌다]를 제공해 드리며 [국립 중앙 박물관] 방문 후 부기토크를 진행합니다."
            }
          </P>
          <P>
            {
              "3월 8일 (토) 오후 3시부터 시작해 박물관 관람 이후 저녁식사까지 하며 마무리 될 예정입니다."
            }
          </P>
          <P>
            {"신청은 선착순이며, 가능 여부에 대해 개별 연락으로 안내 드립니다."}
          </P>
          <Divider />
          <TextInput
            id="data2"
            key="name_input_value"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder={"디스코드 닉네임"}
            onChange={(e) => setName(e.target.value)}
          />
          <LinkBtn
            onClick={() => (window.location.href = `/meetup/join?name=${name}`)}
          >
            대면모임 신청서 제출
          </LinkBtn>
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};

export default Meetup;
