import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
  P,
} from "../../Components/Text";
import HeaderTitle from "../../Components/Title";
import GAHoc from "../../GA";
import Logo from "../../Components/Logo";
import bookData from "../../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProgressBar from "../../Components/ProgressBar";
import TitleSection from "../../Components/TitleSection";
import Book from "../../Components/Book";
import TurningPage from "../../Components/TurningPage";
import { changeHandler } from "../../Components/MemberChecker";
import { expired } from "../../Components/Utils";

const StateResponder = ({
  state,
  goNext,
  goPrev,
  inputRef,
  month_kr,
  name,
  booklist,
  setProgress,
  setName,
  setDispState,
}) => {
  switch (state) {
    case 0:
      return (
        <VerticalBox>
          <TitleSection month_kr={month_kr} />
          <P>{"사용자의 닉네임을 입력하세요"}</P>
          <TextInput
            ref={inputRef}
            type="text"
            onChange={(e) => changeHandler(e, setName, setDispState, 1)}
            placeholder={"닉네임"}
          />
        </VerticalBox>
      );
    case 1:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} first>
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
10월에 이야기 할 '책을 읽는 이유'는 휴식입니다.

많은 정보와 빠른 변화로부터 벗어난 휴식은

책을 통해 온전히 가능해지곤 합니다.
`}</Pre>
          </VerticalBox>
        </TurningPage>
      );
    case 2:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} setProgress={setProgress}>
          <Pre>{`
휴식;감각의 집중

태도의 변화를 얻거나 모르던 것을 배우기 위해서는

집중해서 의미를 파악하며 책을 읽어내려 갈 필요가 있습니다.

그러나 휴식을 위해 책을 읽을 때는 늘 집중하고 있어야 할 필요가 없습니다.

이때 의미있는 것은 책의 내용과 주제의식이 아닌 책을 읽는 시간 그 자체이기 때문입니다.
          `}</Pre>
        </TurningPage>
      );
    case 3:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
이 달의 책은 파트리크 쥐스킨트의 <향수>입니다.

쉴 때 편하게 읽고 난 뒤에도 이야깃거리가 남을 우리 시대의 명작이죠.

이해하려 노력하기보다는 장면을 상상하고 감정을 느끼며 직관적으로 읽어보세요.

영화를 보며 쉴 때와는 다른 종류의 휴식을 경험할 수 있을거에요.
`}</Pre>
        </TurningPage>
      );

    case 4:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} last>
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <Divider />
            {Object.entries(booklist).map(([k, v]) => {
              return <Book key={k} objKey={k} bookInfo={v} />;
            })}
            <Divider />
            <div
              style={{
                backgroundColor: "#c7c9cc",
                padding: "20px 15px",
                borderRadius: "5px",
                margin: "10px 0px",
              }}
            >
              <Pre>{`📖 <향수> 
            
그는 아주 탐욕스러웠다. 그의 냄새 사냥의 목적은 이 세상에서 냄새라고 부를 수 있는 모든 것을 소박하게 있는 그대로 소유하는 것이었다. 

단 한 가지 조건은 오로지 그것들이 새로운 냄새여야 한다는 점이었다. 

그에게는 말의 땀에서 나는 냄새도 막 피어나는 장미 꽃송이의 부드럽고 푸른 냄새에 못지않게 중요했다.`}</Pre>
            </div>
          </VerticalBox>
        </TurningPage>
      );
    default:
      return (
        <HorizontalBox>
          <Info>안녕하세요 !</Info>
          <Logo src="/charactor1.png" alt="logo" />
        </HorizontalBox>
      );
  }
};

const BooklistOct = () => {
  const [name, setName] = useState("부기");
  const [progress, setProgress] = useState(0);
  const { month } = useParams();
  const { kr: month_kr, booklist } = bookData["2024"][month];
  const [dispState, setDispState] = useState(expired(month_kr[0]) ? 1 : 0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);
  useEffect(() => {
    setProgress(dispState / 4);
  }, [dispState]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 0) {
      inputRef.current.focus();
    }
    if ((!expired(month_kr[0]), name === "부기" && dispState > 0)) {
      setDispState(0);
    }
  }, [name, dispState, month_kr]);

  const goNext = () => {
    setDispState(dispState + 1);
  };
  const goPrev = () => {
    setDispState(dispState - 1);
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder
          state={dispState}
          goNext={goNext}
          goPrev={goPrev}
          inputRef={inputRef}
          setDispState={setDispState}
          setName={setName}
          month_kr={month_kr}
          name={name}
          booklist={booklist}
          setProgress={setProgress}
        />
      </Container>
    </GAHoc>
  );
};
export default BooklistOct;
