import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
  P,
} from "../../Components/Text";
import HeaderTitle from "../../Components/Title";
import GAHoc from "../../GA";
import Logo from "../../Components/Logo";
import bookData from "../../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProgressBar from "../../Components/ProgressBar";
import TitleSection from "../../Components/TitleSection";
import Book from "../../Components/Book";
import TurningPage from "../../Components/TurningPage";
import { changeHandler, validCheck } from "../../Components/MemberChecker";
import { expired } from "../../Components/Utils";

const StateResponder = ({
  state,
  goNext,
  goPrev,
  month_kr,
  name,
  inputRef,
  booklist,
  setProgress,
  setName,
  setDispState,
  autoComplete,
  setAutoComplete,
}) => {
  switch (state) {
    case 0:
      return (
        <VerticalBox>
          <TitleSection month_kr={month_kr} />
          <P>{"사용자의 닉네임을 입력하세요"}</P>
          <TextInput
            ref={inputRef}
            type="text"
            onChange={(e) =>
              changeHandler(e, setName, setDispState, 1, setAutoComplete)
            }
            placeholder={"닉네임"}
            onKeyUp={(e) => {
              if (e.key === "Backspace") {
                setAutoComplete([]);
              }
            }}
          />
          {autoComplete.length >= 1
            ? autoComplete.map((item) => {
                return (
                  <TextInput
                    key={item.name}
                    onClick={() => {
                      setName(item.name);
                      validCheck(item.expireAt, setDispState, 1);
                    }}
                    style={{
                      border: "none",
                      margin: "0px",
                      fontSize: "12px",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    readOnly
                    value={item.name}
                  />
                );
              })
            : null}
        </VerticalBox>
      );
    case 1:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} first>
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
2025년의 첫번째 키워드 '일'에 대한 이야기는

1월에 이어 2월에도 계속됩니다.
  `}</Pre>
          </VerticalBox>
        </TurningPage>
      );
    case 2:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} setProgress={setProgress}>
          <Pre>{`
1월에는 일을 하는 태도와 마음가짐에 대해 이야기 했습니다.

그러나 태도와 마음이 우리를 대신하여 일을 해주는 것은 아닙니다.

좋은 목표를 세우는 일이 좋은 일의 결과를 늘 보장하지도 않습니다.
            `}</Pre>
        </TurningPage>
      );
    case 3:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
더 나음을 추구하는 태도와 마음은 현실로 전환되어야만 합니다.

"일을 대하는 건강한 태도와 마음을 현실로 바꾸게 해주는 시도"

이것이 제가 생각하는 일의 본질입니다.

따라서 이번 달에는 여러분이 높은 가치를 두는 것을 현실 세계에 구현할 수 있는 방법에 대해 읽어보시길 추천합니다.
`}</Pre>
        </TurningPage>
      );
    case 4:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
2월의 책은 디자인 책입니다.

디자인은 제가 일을 하는 방식이자 제가 만들고 싶은 세계의 모습입니다.

"제한된 환경이 만드는 어려움을 포착하고, 그 해결책을 만들거나 선택하는 것."

디자인 안에는 개인의 서사와 현실화의 시도가 모두 담겨 있습니다.
  `}</Pre>
        </TurningPage>
      );
    case 5:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} last>
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <Divider />
            {Object.entries(booklist).map(([k, v]) => {
              return <Book key={k} objKey={k} bookInfo={v} />;
            })}
            <Divider />
          </VerticalBox>
        </TurningPage>
      );
    default:
      return (
        <HorizontalBox>
          <Info>안녕하세요 !</Info>
          <Logo src="/charactor1.png" alt="logo" />
        </HorizontalBox>
      );
  }
};
const BooklistFeb = () => {
  const [name, setName] = useState("부기");
  const [progress, setProgress] = useState(0);
  const { month } = useParams();
  const { kr: month_kr, booklist } = bookData["2025"][month];
  const [dispState, setDispState] = useState(expired(month_kr[0]) ? 1 : 0);
  const [autoComplete, setAutoComplete] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);
  useEffect(() => {
    setProgress(dispState / 4);
  }, [dispState]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 0) {
      inputRef.current.focus();
    }
    if ((!expired(month_kr[0]), name === "부기" && dispState > 0)) {
      setDispState(0);
    }
  }, [name, dispState, month_kr]);

  const goNext = () => {
    setDispState(dispState + 1);
  };
  const goPrev = () => {
    setDispState(dispState - 1);
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder
          state={dispState}
          goNext={goNext}
          goPrev={goPrev}
          inputRef={inputRef}
          setDispState={setDispState}
          setName={setName}
          month_kr={month_kr}
          name={name}
          booklist={booklist}
          setProgress={setProgress}
          autoComplete={autoComplete}
          setAutoComplete={setAutoComplete}
        />
      </Container>
    </GAHoc>
  );
};
export default BooklistFeb;
