import React, { useState, useRef } from "react";
import GAHoc from "../../GA";
import { Container, Divider } from "../../Components/Layout";
import {
  P,
  PGray,
  Pre,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../../Components/Text";
import memberData from "../../Data/member.json";
import { LinkBtn, SelectBtn } from "../../Components/Button";
import { sendPayEmail } from "../../Components/Utils";
import HeaderTitle from "../../Components/Title";

const StateResponder = ({
  state,
  inputRef,
  free,
  setSelected,
  selected,
  changeHandler,
  payHandler,
  setDispState,
  name,
}) => {
  const alertUnable = () =>
    alert("이미 마감되었습니다 🥺 다음 모임에 뵙겠습니다.");

  switch (state) {
    case "name":
      return (
        <VerticalBox>
          <SectionTitle>{"사용자의 닉네임을"}</SectionTitle>
          <SectionTitle>{"입력하세요"}</SectionTitle>
          <TextInput
            ref={inputRef}
            type="text"
            onChange={changeHandler}
            placeholder={"닉네임"}
          />
        </VerticalBox>
      );
    case "inform":
      return (
        <VerticalBox>
          <SectionTitle>3월 대면모임</SectionTitle>
          <Pre>{`
          3월 대면모임은 4월 5일 토요일에 진행되며, 오프라인으로는 성수동의 카페; 성수지앵에서, 온라인으로는 디스코드 내; 식탁에서 참여하실 수 있습니다.

          시간은 12시 ~ 3시 / 3시 ~ 6시 두 타임이 준비되어 있습니다.
          `}</Pre>
          <Pre
            style={{ textDecorationLine: "underline", cursor: "pointer" }}
            onClick={() => (window.location.href = "https://naver.me/FwnSgqpu")}
          >
            장소 확인하기
          </Pre>
          <Divider />
          <SectionTitle>참여 방식 선택</SectionTitle>
          <SelectBtn
            key={1}
            isSelected={selected === 1}
            unabled
            onClick={() => alertUnable()}
          >
            12:00 ~ 15:00 오프라인
          </SelectBtn>
          <SelectBtn
            key={2}
            isSelected={selected === 2}
            unabled
            onClick={() => alertUnable()}
          >
            15:00 ~ 18:00 오프라인
          </SelectBtn>
          <SelectBtn
            key={3}
            isSelected={selected === 3 ? true : false}
            onClick={(e) => setSelected(3)}
          >
            12:00 ~ 15:00 온라인
          </SelectBtn>
          <SelectBtn
            key={4}
            isSelected={selected === 4 ? true : false}
            onClick={(e) => setSelected(4)}
          >
            15:00 ~ 18:00 온라인
          </SelectBtn>
          <P>
            {`
              ${name}님이 담긴 영상 혹은 사진이 독서모임의 마케팅 용도로 사용될 수 있으며
              대면모임 참석 신청을 하시는 경우 이에 동의하신 것으로 간주합니다.
              `}
          </P>
          {!free ? (
            <>
              <P>3월 대면모임 이용료는 1만 5천원입니다</P>
              <LinkBtn onClick={() => payHandler("네이버")}>
                네이버 페이로 송금
              </LinkBtn>
              <LinkBtn onClick={() => payHandler("카카오")}>
                카카오 페이로 송금
              </LinkBtn>
              <PGray>
                간편송금 이용이 어려운 분들은 아래의 계좌번호로 직접
                입금해주세요.
              </PGray>
              <PGray>(1002-133-822329 우리은행)</PGray>
            </>
          ) : (
            <>
              <LinkBtn onClick={() => payHandler("연간 이용")}>
                신청하기
              </LinkBtn>
            </>
          )}
          <PGray>대면모임 참석 확인 시 디스코드 메세지가 전송되며</PGray>
          <PGray>최대 1일 소요됩니다.</PGray>
        </VerticalBox>
      );
    default:
      setDispState("name");
      return <VerticalBox>default</VerticalBox>;
  }
};

const MeetupJoin = () => {
  const [dispState, setDispState] = useState("name");
  const [selected, setSelected] = useState(0);
  const [name, setName] = useState("");
  const [free, setFree] = useState(false);
  const inputRef = useRef();
  const changeHandler = ({ target: { value } }) => {
    if (Object.keys(memberData).includes(value)) {
      setName(value);
      setDispState("inform");
      if (
        memberData[value].role === "Boogi Standard" ||
        memberData[value].role === "Boogi Plus"
      ) {
        setFree(true);
      }
    }
  };
  const payHandler = (platform) => {
    sendPayEmail(name, "대면모임", 3, platform, selected);
  };

  return (
    <GAHoc>
      <HeaderTitle title={"모임 신청 | 3월"} />
      <Container style={{ paddingTop: "0px" }}>
        <StateResponder
          state={dispState}
          setDispState={setDispState}
          payHandler={payHandler}
          changeHandler={changeHandler}
          selected={selected}
          setSelected={setSelected}
          free={free}
          inputRef={inputRef}
          name={name}
        />
      </Container>
    </GAHoc>
  );
};

export default MeetupJoin;
