import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
  P,
} from "../../Components/Text";
import HeaderTitle from "../../Components/Title";
import GAHoc from "../../GA";
import Logo from "../../Components/Logo";
import bookData from "../../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProgressBar from "../../Components/ProgressBar";
import TitleSection from "../../Components/TitleSection";
import Book from "../../Components/Book";
import TurningPage from "../../Components/TurningPage";
import { changeHandler, validCheck } from "../../Components/MemberChecker";
import { expired } from "../../Components/Utils";

const StateResponder = ({
  state,
  goNext,
  goPrev,
  month_kr,
  name,
  inputRef,
  booklist,
  setProgress,
  setName,
  setDispState,
  autoComplete,
  setAutoComplete,
}) => {
  switch (state) {
    case 0:
      return (
        <VerticalBox>
          <TitleSection month_kr={month_kr} />
          <P>{"사용자의 닉네임을 입력하세요"}</P>
          <TextInput
            ref={inputRef}
            type="text"
            onChange={(e) =>
              changeHandler(e, setName, setDispState, 1, setAutoComplete)
            }
            placeholder={"닉네임"}
            onKeyUp={(e) => {
              if (e.key === "Backspace") {
                setAutoComplete([]);
              }
            }}
          />
          {autoComplete.length >= 1
            ? autoComplete.map((item) => {
                return (
                  <TextInput
                    key={item.name}
                    onClick={() => {
                      setName(item.name);
                      validCheck(item.expireAt, setDispState, 1);
                    }}
                    style={{
                      border: "none",
                      margin: "0px",
                      fontSize: "12px",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    readOnly
                    value={item.name}
                  />
                );
              })
            : null}
        </VerticalBox>
      );
    case 1:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} first>
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
부기의 2025년은 2달 간 하나의 키워드를 다루며 진행됩니다. 

키워드를 다루는 첫번째 달에는 다양한 책을 추천하며, 

여러분이 책 목록에서 고르거나 평소 읽고 싶었던 책 중 하나를 주제와 상관 없더라도 자유롭게 읽는 식으로 진행합니다.

여러분이 책을 읽으며 쉘하우스에 담아둔 모든 내용과 생각들을 모아 부기는 새로운 연결을 만들어 낼 것입니다.
  `}</Pre>
          </VerticalBox>
        </TurningPage>
      );
    case 2:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} setProgress={setProgress}>
          <Pre>{`
2025년의 두번째 키워드는 '시간'입니다.

우리의 대화에는 늘 시간에 대한 것이 빠지지 않습니다. 

어떤 것을 주제로 하건 과거나, 미래를, 또는 현재를 다루기 때문입니다.
            `}</Pre>
        </TurningPage>
      );
    case 3:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
그러나 시간의 물리적 본질을 파고들다보면, 시간이란 존재하지 않음을 발견하게 됩니다.

우리의 세계는 시간과 사물, 상태 대신에 사건의 총체로 이루어져 있습니다.

때문에 시간이란 변화를 의미한다고 봅니다.

그래서 부제가 될 키워드는 '변화'입니다.

우리는 시간이라는 기준 위에서 변화에 대해 이야기할 것입니다.
  `}</Pre>
        </TurningPage>
      );
    case 4:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
첫 번째 달인 3월에는 세계의 변화와 흐름을 짚어갈 것입니다.

역사를 이야기하며 그 속에서 변화를 찾을 것입니다.

시간이라는 틀을 대신하여 변화를 이야기할 때의 장점은 미래가 미지의 것이자 불안이나 공포가 아니라 지금 내가 쥐고 있는 흐름의 일부가 된다는 것입니다.

때문에 앞으로 우리가 살게될 미래, 다가올 시간에 대해서도 이야기할 수 있겠습니다.
  `}</Pre>
        </TurningPage>
      );
    case 5:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} last>
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <Divider />
            {Object.entries(booklist).map(([k, v]) => {
              return (
                <>
                  <Book key={k} objKey={k} bookInfo={v} />
                  <Divider />
                </>
              );
            })}
          </VerticalBox>
        </TurningPage>
      );
    default:
      return (
        <HorizontalBox>
          <Info>안녕하세요 !</Info>
          <Logo src="/charactor1.png" alt="logo" />
        </HorizontalBox>
      );
  }
};
const BooklistMar = () => {
  const [name, setName] = useState("부기");
  const [progress, setProgress] = useState(0);
  const { month } = useParams();
  const { kr: month_kr, booklist } = bookData["2025"][month];
  const [dispState, setDispState] = useState(expired(month_kr[0]) ? 1 : 0);
  const [autoComplete, setAutoComplete] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);
  useEffect(() => {
    setProgress(dispState / 4);
  }, [dispState]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 0) {
      inputRef.current.focus();
    }
    if ((!expired(month_kr[0]), name === "부기" && dispState > 0)) {
      setDispState(0);
    }
  }, [name, dispState, month_kr]);

  const goNext = () => {
    setDispState(dispState + 1);
  };
  const goPrev = () => {
    setDispState(dispState - 1);
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder
          state={dispState}
          goNext={goNext}
          goPrev={goPrev}
          inputRef={inputRef}
          setDispState={setDispState}
          setName={setName}
          month_kr={month_kr}
          name={name}
          booklist={booklist}
          setProgress={setProgress}
          autoComplete={autoComplete}
          setAutoComplete={setAutoComplete}
        />
      </Container>
    </GAHoc>
  );
};
export default BooklistMar;
