import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
  PGray,
} from "../../Components/Text";
import HeaderTitle from "../../Components/Title";
import GAHoc from "../../GA";
import Logo from "../../Components/Logo";
import bookData from "../../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProgressBar from "../../Components/ProgressBar";
import TitleSection from "../../Components/TitleSection";
import userData from "../../Data/member.json";
import UserCard from "../../Components/UserCard";
import { changeHandler } from "../../Components/MemberChecker";
import { expired } from "../../Components/Utils";

const BooklistJuly = () => {
  const { month } = useParams();
  const { kr: month_kr } = bookData["2024"][month];
  const [name, setName] = useState("부기");
  const [progress, setProgress] = useState(0);
  const [dispState, setDispState] = useState(expired(month_kr[0]) ? 2 : 0);

  const progressUpdate = (num) => {
    if (num < 1.0005) setProgress(num);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);

  useEffect(() => {
    const greeting = () => {
      const greetingTimerId = setTimeout(() => {
        progressUpdate(progress + 1 / 1000);

        if (progress >= 1) {
          setProgress(0);
          clearTimeout(greetingTimerId);
          setDispState(dispState + 1);
        }
      }, 1);
      return greetingTimerId;
    };
    const changeDisp = () => {
      const changeDispTimerID = setTimeout(() => {
        progressUpdate(progress + 1 / 3500);

        if (progress >= 1 && progress < 1.0005) {
          setProgress(0);
          clearTimeout(changeDispTimerID);
          setDispState(dispState + 1);
        }
      }, 1);
      return changeDispTimerID;
    };

    if (dispState === 0) {
      greeting();
      return () => clearTimeout(greeting());
    } else if (2 <= dispState && dispState <= 5) {
      changeDisp();
    }
  }, [dispState, progress]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 1) {
      inputRef.current.focus();
    }
    if (!expired(month_kr[0]) && name === "부기" && dispState > 1) {
      setDispState(1);
    }
  }, [name, dispState, month_kr]);

  // const changeHandler = ({ target: { value } }) => {
  //   if (Object.keys(memberData).includes(value)) {
  //     setName(value);
  //     setDispState(2);
  //   }
  // };
  const StateResponder = ({ state }) => {
    switch (state) {
      case 0:
        return (
          <VerticalBox>
            <TitleSection month_kr={month_kr} />;
          </VerticalBox>
        );
      case 1:
        return (
          <VerticalBox>
            <SectionTitle>{"사용자의 닉네임을"}</SectionTitle>
            <SectionTitle>{"입력하세요"}</SectionTitle>
            <TextInput
              ref={inputRef}
              type="text"
              onChange={(e) => changeHandler(e, setName, setDispState, 2)}
              placeholder={"닉네임"}
            />
          </VerticalBox>
        );
      case 2:
        return (
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
            ${name} 님은 그간 부기와 함께 여러 권의 책을 읽어 왔습니다. 

            개별의 책들은 서로 다른 정도의 인상을 기억 속에 남겼겠지만, 
            
            ${name} 님이 읽어간 모든 문장들은 각각의 방식으로 말과 행동, 그리고 사고방식에 영향을 미치고 있습니다.
            
            그런만큼 ${name} 님의 이야기는 부기 님이 읽어 온 책들과 밀접히 연결되어 있지 않을까요 ?
            `}</Pre>
          </VerticalBox>
        );
      case 3:
        return (
          <VerticalBox>
            <Pre>{`
            7월에는 각양각색의 캐릭터를 가진 운영진의 이야기를 소개합니다. 

            그리고 그들의 이야기의 일부가 된 책들을 또 추천할게요.
            `}</Pre>
          </VerticalBox>
        );
      case 4:
        return (
          <VerticalBox>
            <Pre>{`
            그러고 나면 ${name} 님에게는 중요한 독서과제가 하나 주어집니다.

            최근에 ${name} 님이 읽어온 책들을 골라 소개하며, 
            
            그 책을 고르고 읽은 ${name} 님의 취향, 사고 방식, 기준 등을 소개하는 글을 적는 것이 그것입니다.
            
            그  7월을 마무리하면서는 다른 멤버 분들과 함께 각자의 책 소개를 공유 해보려 합니다.
            `}</Pre>
          </VerticalBox>
        );
      case 5:
        return (
          <VerticalBox>
            <Pre>{`
            이 달의 추천 책은 운영진이 읽어 온 것들로 공개합니다. 

            책 목록에 들어가기 전 운영진의 소개글은 ${name}님이 이번 달 작성할 글의 예시이기도 합니다.
            `}</Pre>
          </VerticalBox>
        );

      case 6:
        return (
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <PGray>
              {name} 님과 닮아있는 사람을 골라 그가 읽어 온 책을 들여다보세요 🐢
            </PGray>
            <Pre>화살표를 눌러 책 목록을 확인하세요.</Pre>
            <Divider />
            {Object.entries(userData).map(([k, v]) => {
              if (k === "느꾸맨" || k === "체다" || k === "대그로") {
                console.log(k);
                return <UserCard key={k} objKey={k} user={{ name: k, ...v }} />;
              } else {
                return null;
              }
            })}
          </VerticalBox>
        );
      default:
        return (
          <HorizontalBox>
            <Info>안녕하세요 !</Info>
            <Logo src="/charactor1.png" alt="logo" />
          </HorizontalBox>
        );
    }
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder state={dispState} />
      </Container>
    </GAHoc>
  );
};
export default BooklistJuly;
