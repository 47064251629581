import React from "react";
import GAHoc from "../../GA";
import { Container } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import { P, SectionTitle, VerticalBox } from "../../Components/Text";
import Logo from "../../Components/Logo";
import { LinkBtn } from "../../Components/Button";

const Thanks = () => {
  const params = new URLSearchParams(document.location.search);
  const link = params.get("l");
  const toLink = () => {
    console.log(link);
    window.location.href = link;
  };
  return (
    <GAHoc>
      <Container>
        <HeaderTitle title="감사합니다 | 부기북스" />
        <VerticalBox>
          <Logo src="/logo.png" alt="logo" />
          <SectionTitle>등록이 완료되었습니다.</SectionTitle>
          <P>결제가 완료 후 서버에 등록되는데 시간이 좀 걸립니다.</P>
          <P>
            결제 확인 후 서버에 등록되기 전까지 웹상에서는 미등록 상태이며,
            24시간 내로 등록 후 개인 DM을 통해 등록 완료를 안내드릴 예정입니다.
          </P>
          <LinkBtn onClick={toLink}>결제 링크 열기</LinkBtn>
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};

export default Thanks;
